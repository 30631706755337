import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import * as classes from './ImageViewerModal.module.css';

function ImageViewerModal({open, onClose, imgURL, imgAlt}) {
  const [imgLoading, setImgLoading] = React.useState(true);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      document.documentElement.style.overflow = 'unset';
    }
  }, [open]);

  const onImgLoad = () => {
    setImgLoading(false);
  };

  return (
    <Modal contentClassName={classes.modalContent} centered show={open} onHide={onClose} size="xl">
      <Modal.Header className={classes.modalHeader} closeButton />
      <Modal.Body className={classes.modalBody}>
        {imgLoading && (
          <div className={classes.spinnerContainer}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <img
          style={{display: imgLoading ? 'none' : undefined}}
          className={classes.modalImg}
          onLoad={onImgLoad}
          src={imgURL}
          alt={imgAlt}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ImageViewerModal;
