import React from 'react';

function EmbedPaperForm({formId, prefill, onSubmission}) {
  React.useEffect(() => {
    if (onSubmission) {
      window.addEventListener('PaperformSubmission', onSubmission);
    }

    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed';
    document.body.appendChild(script);

    return () => {
      if (onSubmission) {
        window.removeEventListener('PaperformSubmission', onSubmission);
      }
      script.remove();
    };
  }, [onSubmission]);

  return <div key={`${prefill}${formId}`} data-paperform-id={formId} data-prefill={prefill} />;
}

export default EmbedPaperForm;
