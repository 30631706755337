import React from 'react';
import queryString from 'query-string';
import * as ReactGA from 'react-ga';
import createPersistedState from 'use-persisted-state';
import EmbedPaperForm from './EmbedPaperForm';

const useGaClientIdState = createPersistedState('google-analytics-client-id');

const DEFAULT_GA_CLIENT_ID = 'UNKNOWN';

function EmbedContactForm({
  paperformId,
  paperformSubmissionHandler,
  searchParamsKey = '',
  searchParams = '',
}) {
  const [gaClientId, setGaClientId] = useGaClientIdState(DEFAULT_GA_CLIENT_ID);
  const [prefillData, setPrefillData] = React.useState('');

  React.useEffect(() => {
    ReactGA.ga(tracker => {
      const newGaClientId = tracker.get('clientId');
      if (gaClientId === DEFAULT_GA_CLIENT_ID || newGaClientId !== gaClientId) {
        setGaClientId(newGaClientId);
      }
    });
  }, []);

  React.useEffect(() => {
    const localSearchParams = {
      ...queryString.parse(searchParams),
      ...queryString.parse(searchParamsKey ? localStorage.getItem(searchParamsKey) : ''),
    };

    localSearchParams.cid = gaClientId;

    setPrefillData(queryString.stringify(localSearchParams));
  }, [gaClientId]);

  return (
    <EmbedPaperForm
      formId={paperformId}
      prefill={prefillData}
      onSubmission={paperformSubmissionHandler}
    />
  );
}

export default EmbedContactForm;
